@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .main-content {
    @apply bg-gray-800 h-screen w-screen;
  }
  .hero {
    @apply text-8xl text-center content-center text-slate-400 drop-shadow-lg font-mono;
  }

  .radio-buttons {
    @apply text-5xl text-center text-black drop-shadow-lg font-mono h-10 w-12;
  }

  .submit-button {
    @apply bg-transparent hover:bg-gray-900 text-gray-900 font-semibold hover:text-white py-2 px-4 border hover:border-transparent rounded;
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: rgb(31, 41, 55);
    margin: 0 auto;
    padding: 0;
    max-width: 1200px;
  }
  
  .card {
    border: 2px solid rgb(31, 41, 55);
    border-radius: 8px;
    background-color: rgb(203, 213, 225);
    padding: 16px;
    margin: 16px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }
  

  
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .card {
      width: 100%;
    }
  }
}
